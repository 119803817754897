
import React from 'react';
import placuta from '../resources/AIMMAIPE-Cluj-scaled.jpg';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    // <footer>
    //   <div>
    //     <h3>Sign up</h3>
    //     <form>
    //       <label htmlFor="email">Email:</label>
    //       <input type="email" id="email" name="email" required />
    //       <label htmlFor="password">Password:</label>
    //       <input type="password" id="password" name="password" required />
    //       <button type="submit">Sign up</button>
    //     </form>
    //   </div>
    //   <div>
    //     <h3>Log in</h3>
    //     <form>
    //       <label htmlFor="email">Email:</label>
    //       <input type="email" id="email" name="email" required />
    //       <label htmlFor="password">Password:</label>
    //       <input type="password" id="password" name="password" required />
    //       <button type="submit">Log in</button>
    //     </form>
    //   </div>
    // </footer>
    <div className="footer" style={{zIndex: 1000,}}>
          
          <div className="footer-links">
          <Link to="/contact" id="contact">Contact</Link>
          <Link to="/termeniconditii" id="termeni">Termeni si conditii</Link>
          </div>
          <p className='contact'>@2023 Poptprint Match S.R.L. - Cluj - Napoca, Str. Plopilor 73</p>
          
          
          <img src={placuta} alt="placuta" id="placuta" />

          {/* <CSignInUp /> */}
          

        </div>
  );
}

export default Footer;
