import React, { useState } from 'react';
import SearchPage from './searchbar.css';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const navigate = useNavigate();
  
  const counties = ["Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila", "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava", "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"]; // Replace with actual county names
  const jobCategories = ["Servicii de sănătate",
  "Servicii educaționale",
  "Servicii financiare",
  "Servicii de consultanță în afaceri",
  "Servicii IT și tehnologie",
  "Servicii de marketing și publicitate",
  "Servicii de catering și evenimente",
  "Servicii de transport și logistică",
  "Servicii de turism și ospitalitate",
  "Servicii de construcții și reparații"]; // Replace with actual job categories

  const handleSearch = () => {
    const searchQuery = {
      searchText,
      selectedCounty,
      selectedCategory,
    };
    const queryString = `?searchText=${searchText}&selectedCounty=${selectedCounty}&selectedCategory=${selectedCategory}`;
    navigate(`/searchresults${queryString}`);
    ;
  };

  return (
    <div className='searchBar'>
      <input id="searchCauta"
        type="text"
        placeholder="Cauta servicii..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {/* <select id="searchJudet"
        value={selectedCounty}
        onChange={(e) => setSelectedCounty(e.target.value)}
      >
        <option value="0">Toate judetele</option>
        {counties.map((county) => (
          <option key={county} value={county}>{county}</option>
        ))}
      </select> */}
      <select id="searchCategorie"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="0">Toate Categoriile</option>
        {jobCategories.map((category) => (
          <option key={category} value={category}>{category}</option>
        ))}
      </select>
      <button onClick={handleSearch}>Cauta</button>
    </div>
  );
};

export default SearchBar;