import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import "./anunt.css";
import axios from 'axios';
import { useState } from 'react';
import SearchBar from './SearchBar';
import Contact from './Contact';
import Footer from './Footer';
function Anunt() {
    
    const location = useLocation();
    const item = location.state.selectedItem;
    const [userDetails, setUserDetails] = useState({});
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);

    const handlePhoneNumberClick = () => {
    setShowPhoneNumber(true);
    };


    useEffect(() => {
      axios.post('http://localhost:5001/userdetails', { userId: item.userid }, {
        headers: {
          
        }
      })
      .then((response) => {
        setUserDetails(response.data);
        
      })
      .catch((error) => {
        console.error(error);
      });
    }, []);
  

    return (
      <div className='anunt-container'>
        <div className="searchaa">
          <SearchBar/>
        </div>
        <div className=".">
          </div>

        <div className="anuntDet">

        

        <div className="anunt-details">
        <img src={`http://localhost:5001/${item.image}`} alt="imagine anunt" />
        <h3 style={{ textAlign: "center"}}>{item.nume_anunt}</h3>
        <p>Data adaugarii: {new Date(item.data_add).toLocaleDateString()}</p>
        <p>Categoria: {item.category}</p>
        <p>Locația: {item.location}</p>
        
        <p>Descriere: {item.description}</p>
        <p>Pentru ofertă va rugăm contactați-ne</p>
        <div className="telefon" onClick={handlePhoneNumberClick}>
            Telefon: {showPhoneNumber ? item.phone_number : "Apasa pentru a vedea numarul de telefon"}
        </div>
        
        
        </div>
        </div>

        {/* <div className="user-details">
        <h3 style={{ textAlign: "center"}}>Datele utilizatorului</h3>
        <img src={`http://localhost:5001/${userDetails[0]?.profile_img}`} alt="imagine profil" className="profile_img"/>
        <h4>Nume: {userDetails[0]?.username}</h4>
        <br>
        </br>
        <p>{userDetails[0]?.user_description}</p>
        

        </div> */}

        <div className='user-details'>
          <Contact/>
        </div>

        <div className="footer">
          <Footer />
    
        </div>


        
      </div>
      
      
    );
  }

  export default Anunt;