import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
//import "./adaugareanunt.css";

import { useEffect } from "react";
import jwt_decode from 'jwt-decode';
import Footer from "./Footer";


function AdaugareAnunt() {

    const [numeAnunt, setNumeAnunt] = useState('');
    const [dataAdd, setDataAdd] = useState('');
    const [dataExp, setDataExp] = useState('');
    const [userID, setUserID] = useState('');
    const [userName, setUserName] = useState('');
    const [categorie, setCategorie] = useState('');
    const [locatie, setLocatie] = useState('');
    const [descriere, setDescriere] = useState('');
    const [phone, setPhone] = useState('');
    const [pret, setPret] = useState('');
    const [servicii, setServicii] = useState(0);
    const [urgent, setUrgent] = useState(0);
    
    const counties = ["Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila", "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava", "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"];
    const [selectedImage, setSelectedImage] = useState(null);
    // const handleImageUpload = (event) => {
    //     setSelectedImage(event.target.files[0]);
    //   };

    let navigate = useNavigate();
    const token = localStorage.getItem('token');

    
    //setDatAdd with current date
    useEffect(() => {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        setDataAdd(date);
    }, []);

    

    


    const handleFormSubmit = (e) => {
        e.preventDefault();
        
        const decoded = jwt_decode(token);
        
        let formData = new FormData();
        formData.append('numeAnunt', numeAnunt);
        formData.append('dataAdd', dataAdd);
        formData.append('dataExp', dataExp);
        formData.append('userId', decoded.userId);
        formData.append('userName', decoded.username);
        formData.append('categorie', categorie);
        formData.append('locatie', locatie);
        formData.append('descriere', descriere);
        formData.append('phone', phone);
        formData.append('pret', pret);
        formData.append('servicii', servicii);
        formData.append('urgent', urgent);
        formData.append('image', selectedImage); // Append the uploaded image file
        
        axios.post('http://localhost:5001/adaugareAnunt', formData,{
            headers: { 'Content-Type': 'multipart/form-data' ,
                        'Authorization': token  },
        })
            .then((response) => {
                alert(response.data.message);
                navigate('/userprofile');
            })
            .catch((error) => {
                console.error(error);
                alert(error.response.data.message);}
            )
    };

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        setSelectedImage(selectedImage);
      };
    

    return (<div>
        <div className="adaugareAnunt">
            <h1>Adăugare anunț</h1>
            <br></br>
            <br></br>
            
            
            <form onSubmit={handleFormSubmit} className='formSubmit'>
                <label htmlFor="numeAnunt">Nume anunț</label>
                <input type="text" id="numeAnunt" value={numeAnunt} onChange={(e) => setNumeAnunt(e.target.value)} />
                
                <label htmlFor="image">Imagine</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageUpload} />


                <label htmlFor="dataExp">Dată expirare</label>
                <input type="date" id="dataExp" value={dataExp} onChange={(e) => setDataExp(e.target.value)} />

                <label htmlFor="categorie">Categorie</label>
                <select id="categorie" value={categorie} onChange={(e) => setCategorie(e.target.value)}>
                    <option value="Servicii de sănătate">Servicii de sănătate</option>
                    <option value="Servicii educaționale">Servicii educaționale</option>
                    <option value="Servicii financiare">Servicii financiare</option>
                    <option value="Servicii de consultanță în afaceri">Servicii de consultanță în afaceri</option>
                    <option value="Servicii IT și tehnologie">Servicii IT și tehnologie</option>
                    <option value="Servicii de marketing și publicitate">Servicii de marketing și publicitate</option>
                    <option value="Servicii de catering și evenimente">Servicii de catering și evenimente</option>
                    <option value="Servicii de transport și logistică">Servicii de transport și logistică</option>
                    <option value="Servicii de turism și ospitalitate">ervicii de turism și ospitalitate</option>
                    <option value="Servicii de construcții și reparații">Servicii de construcții și reparații</option>
                    
                </select>

                <label htmlFor="locatie">Locație</label>
                
                <select
                    value={locatie}
                    onChange={(e) => setLocatie(e.target.value)}                >
                    <option value="0">Alege locație</option>
                    {counties.map((county) => (
                    <option key={county} value={county}>{county}</option>
                    ))}
                </select>

                <label htmlFor="descriere">Descriere</label>
                <input type="text" id="descriere" value={descriere} onChange={(e) => setDescriere(e.target.value)} />

                <label htmlFor="pret">Preț</label>
                <input type="text" id="pret" value={pret} onChange={(e) => setPret(e.target.value)} />

                <label htmlFor="phone">Telefon</label>
                <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <br></br>
                <button type="submit">Adaugă anunț</button>
            </form> 
            
        </div>
        <div className="footer">
        <Footer />
        
         </div>
  </div>
        )



}

export default AdaugareAnunt;