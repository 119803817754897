import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import Search from "./Search";
import './searchpage.css';
import axios from "axios";
import AnuntD from "./AnuntD";
import './search.css';
import Footer from "./Footer";

const locationOptions = [
  "Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila",
  "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj",
  "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș",
  "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava",
  "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"
];

const categoryOptions = ["Servicii de sănătate",
"Servicii educaționale",
"Servicii financiare",
"Servicii de consultanță în afaceri",
"Servicii IT și tehnologie",
"Servicii de marketing și publicitate",
"Servicii de catering și evenimente",
"Servicii de transport și logistică",
"Servicii de turism și ospitalitate",
"Servicii de construcții și reparații"];

function SearchResults(){
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPriceMin, setSelectedPriceMin] = useState(0);
  const [selectedPriceMax, setSelectedPriceMax] = useState(10000000);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isUrgent, setIsUrgent] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const searchText = queryParams.get("searchText") || "";
  const selectedCounty = queryParams.get("selectedCounty") || "";
  const selectedCategoryFromUrl = queryParams.get("selectedCategory") || "";

  useEffect(() => {
    const locationState = location.state;
    if (locationState && locationState.searchQuery) {
      setSearchQuery(locationState.searchQuery);
    }
  }, [location.state]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("nume_anunt", searchText);
    formData.append("category", selectedCategoryFromUrl); // Updated variable name
    formData.append("location", selectedCounty);
    formData.append("priceMin", selectedPriceMin);
    formData.append("priceMax", selectedPriceMax);
    formData.append("isUrgent", isUrgent);

    axios.post('http://localhost:5001/searchresults', formData, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      // Filter search results based on price range
      const filteredResults = response.data.filter((result) => {
        const price = parseInt(result.price);
        return price >= selectedPriceMin && price <= selectedPriceMax;
      });

      setSearchResults(filteredResults);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  }, [searchQuery, selectedLocation, selectedPriceMin, selectedPriceMax, selectedCategory, isUrgent]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleGoBack = () => {
    setSelectedItem(null);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleUrgentChange = (e) => {
    setIsUrgent(e.target.checked);
  };

  const handlePriceMinChange = (e) => {
    setSelectedPriceMin(parseInt(e.target.value));
  };

  const handlePriceMaxChange = (e) => {
    setSelectedPriceMax(parseInt(e.target.value));
  };

  return (
    <div>
    <div className="cont1">
      <div className="searcha">
        <SearchBar/>
      </div>
      <div className="filters">
        
        
          <div>
          {/* <label>Locație:</label>
          
          <select value={selectedLocation} onChange={handleLocationChange}>
            <option value="">All</option>
            {locationOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div> */}
          <label>Categorie:</label>
          
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">All</option>
            {categoryOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        
        
        
          
          {/* <div>
            <label>Min:</label>
            <input type="number" value={selectedPriceMin} onChange={handlePriceMinChange} />
          </div>
          <div>
            <label>Max:</label>
            <input type="number" value={selectedPriceMax} onChange={handlePriceMaxChange} />
          </div> */}
        
      </div>
      <div className="results">
        <h2>Rezultatele căutării</h2>
        <div className="search-results">
        {loading ? (
          <p>Loading...</p>
          
        ) : searchResults.length > 0 ? (
          <ul>
            {searchResults.map((result) => (
              <div className="anunt-pr" key={result.idanunt} onClick={() => handleItemClick(result)}>
                <img src={`http://localhost:5001/${result.image}`} alt="img-anunt" id="img-anunt"/>
                <li>
                  <h3>{result.nume_anunt}</h3>
                  <p>{new Date(result.data_add).toLocaleDateString()}</p>
                  <p>Locație: {result.location}</p>
                  <p>Preț: {result.price} RON</p>
                </li>
              </div>
            ))}
          </ul>
        ) : (
          <p>Nu a fost găsit niciun rezultat.</p>
        )}
        </div>

        {selectedItem ? (
          navigate(`/anunt/${selectedItem.idanunt}`, { state: {selectedItem}  })
        ) : null}
      </div>
      
    </div>
    <div className="footer">
    <Footer />
    
    </div>
    </div>
  );
}

export default SearchResults;