import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignIn () {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    let navigate = useNavigate();

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        
        formData.append('username', username);
        formData.append('password', password);
    ;

    axios.post('http://localhost:5001/signin', formData,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
            alert(response.data.message);
            localStorage.setItem('token', response.data.token);
            navigate('/home');
        })
        .catch((error) => {
            console.error(error);
            alert('Wrong username/password');
        });
    ;

    }

    return (
        <div>
            <h1>Sign In</h1>
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="username">Username</label>
                <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />

                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />

                <button type="submit">Sign In</button>
            </form>
            <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
        </div>
    );
}

export default SignIn;
