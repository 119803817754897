import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './userprofile.css';
import AdaugareAnunt from './AdaugareAnunt';
import jwt_decode from 'jwt-decode';
import ListaAnunt from './ListaAnunt';
import Footer from './Footer';



const UserProfile = () => {
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [password, setPassword] = useState('');
  const [userDetails, setUserDetails] = useState({});
  

  let navigate = useNavigate();

  const token = localStorage.getItem('token');
  const decoded = jwt_decode(token);
  console.log(decoded.userId);
  

  const fetchUserDetails = () => {
    
    axios.post('http://localhost:5001/userdetails', {userId}, {
      headers: {
        
      }})
      .then((response) => {
        setUserDetails(response.data);
        
        setFirstName(response.data[0].first_name);
        setLastName(response.data[0].last_name);
        setEmail(response.data[0].email);
        setUsername(response.data[0].username);
        setPhoneNumber(response.data[0].phone_number);
        setProfileImage(response.data[0].profile_image);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  

  useEffect(() => {
    

    if (!token) {
      alert('You are not logged in');
      navigate('/signinup');
    } else {
      setUserId(decoded.userId);
      
      
      fetchUserDetails();
      setFirstName(decoded.firstName);
      setLastName(decoded.lastName);
      setEmail(decoded.email);
      setUsername(decoded.username);
      setPhoneNumber(decoded.phoneNumber);
      setProfileImage(decoded.profileImg);
      
      
    
    }
  }, [userId]);

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];
    setProfileImage(selectedImage);
  };

  

  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await axios.put('http://localhost:5001/user', {
          userId,
          firstName,
          lastName,
          email,
          username,
          phoneNumber,
          profileImage,
          
        });
        alert('Informatia a fost actualizata cu succes!');
      } catch (error) {
        console.error(error);
        alert('A aparut o eroare!');
      }
    };
  



 

  return (
    <div className="userP1">
    <div className="userProfile">
      <div className="numeu">
      <h2>Username: {username}</h2>
      </div>
      <h3 className='profileU'>Schimba date utilizator:</h3>
      <div className="profileUdata">
      <form onSubmit={handleFormSubmit}>
      <label htmlFor="image">Imagine</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageUpload} />
        <div>
          <label>Prenume:</label>
          <input type="text" value={firstName} placeholder={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div>
          <label>Nume:</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Telefon:</label>
          <input type="tel" value={phoneNumber} placeholder='Introdu-ți numarul de telefon' onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
        
          
        <button type="submit">Salvează modificările</button>
      </form>
      
      
      </div>
      
      
      <div className="anuntUdata">
      <h3 className="anuntUdata">Anunțurile tale:</h3>
        <ListaAnunt/>
      
      </div>
      
    </div>
    <div className="footer">
          <Footer />
          
    </div>
    </div>
  );
};

export default UserProfile;