import React, { useState } from "react";

const TermeniConditii = () => {
  
  return (
    <div className="termeni">
       <h2>Termeni și Condiții pentru e-tenet.ro</h2>
        
      <p>Accesul și utilizarea site-ului nostru sunt guvernate de următorii termeni și condiții. Prin înregistrarea unui cont și utilizarea site-ului, sunteți de acord să respectați și să fiți legat de acești termeni. Vă rugăm să citiți cu atenție:</p>

      <h2>Conturi de utilizator:</h2>
      <p>
        a. Pentru a accesa serviciile noastre și a posta anunțuri, trebuie să creați un cont de utilizator.<br />
        b. Furnizarea de informații de înregistrare false sau înșelătoare nu este permisă. Sunteți responsabil pentru păstrarea confidențialității datelor de autentificare ale contului dumneavoastră.
      </p>

      <h2>Responsabilitatea anunțurilor:</h2>
      <p>
        a. Sunteți singurul responsabil pentru conținutul anunțurilor pe care le postați pe site. Asigurați-vă că anunțurile sunt conforme cu legislația aplicabilă și nu încalcă drepturile de autor, drepturile de proprietate intelectuală sau alte drepturi ale terților.<br />
        b. Ne rezervăm dreptul de a îndepărta sau modifica orice anunț care încalcă termenii și condițiilor noastre sau care este considerat inadecvat.
      </p>

      <h2>Drepturile de proprietate intelectuală:</h2>
      <p>
        a. Conținutul site-ului, inclusiv logo-urile, mărcile comerciale, textele, graficele, imagini și alte materiale, sunt protejate de drepturile de proprietate intelectuală ale noastre sau ale terților.<br />
        b. Nu aveți permisiunea de a copia, reproduce, distribui sau modifica conținutul site-ului fără acordul nostru scris.
      </p>

      <h2>Confidențialitate:</h2>
      <p>
        a. Informațiile personale furnizate în timpul înregistrării sau utilizării site-ului vor fi tratate în conformitate cu politica noastră de confidențialitate. Vă rugăm să citiți politica noastră de confidențialitate pentru a înțelege modul în care procesăm și protejăm datele dumneavoastră.
      </p>

      <h2>Interruperea accesului:</h2>
      <p>
        a. Ne rezervăm dreptul de a suspenda sau întrerupe accesul dumneavoastră la site în orice moment și fără notificare prealabilă în cazul încălcării termenilor și condițiilor sau a legilor aplicabile.
      </p>

      <h2>Răspunderea noastră:</h2>
      <p>
        a. Ne străduim să menținem funcționarea site-ului în mod regulat, dar nu putem garanta că acesta va fi întotdeauna disponibil fără întreruperi sau erori.<br />
        b. Nu suntem responsabili pentru niciun prejudiciu, pierdere sau daună cauzată de utilizarea sau imposibilitatea utilizării site-ului.
      </p>

      <h2>Modificări ale termenilor și condițiilor:</h2>
      <p>
        a. Ne rezervăm dreptul de a modifica acești termeni și condiții în orice moment. Orice modificare va intra în vigoare imediat ce este publicată pe site.<br />
        b. Continuarea utilizării site-ului după modificarea termenilor și condițiilor constituie acceptul dumneavoastră cu privire la noile condiții.
      </p>

      <h2>Legislație aplicabilă:</h2>
      <p>
        a. Acești termeni și condiții sunt guvernați de legile României și se interpretează în conformitate cu acestea.
      </p>

      <p>Înregistrând un cont și utilizând site-ul "e-tenet.ro", sunteți de acord să respectați și să fiți legat de acești termeni și condiții. Vă mulțumim pentru utilizarea serviciilor noastre și vă urăm experiențe plăcute pe platforma noastră!</p>
        
    </div>
  );
};

export default TermeniConditii;