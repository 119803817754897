// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import {useState} from "react";
// import "./Categories.css";


// function Categories ({searchTerm}) {

//     const [searchData, setSearchData] = useState("");

//     let servicii_principale = [
//         "Servicii de sănătate",
//         "Servicii educaționale",
//         "Servicii financiare",
//         "Servicii de consultanță în afaceri",
//         "Servicii IT și tehnologie",
//         "Servicii de marketing și publicitate",
//         "Servicii de catering și evenimente",
//         "Servicii de transport și logistică",
//         "Servicii de turism și ospitalitate",
//         "Servicii de construcții și reparații"
//     ]
    
    
    
//     const handleCategory = (term) => {
//         setSearchData(term);
//         searchTerm(term);
//     }
    
    
    
    
    
    
    

//     const responsive = {
//         superLargeDesktop: {
//           // the naming can be any, depends on you.
//           breakpoint: { max: 4000, min: 3000 },
//           items: 5
//         },
//         desktop: {
//           breakpoint: { max: 3000, min: 1024 },
//           items: 5
//         },
//         tablet: {
//           breakpoint: { max: 1024, min: 464 },
//           items: 2
//         },
//         mobile: {
//           breakpoint: { max: 464, min: 0 },
//           items: 1
//         }
//       };


//     return (
//         <div className="carouseld">
//             <Carousel responsive={responsive}>
//               {servicii_principale.map((category) => (
//         <div className="catbtn" key={category} onClick={() => handleCategory(category)}>
          
//             {category}</div>
        
//     ))}
               
//             </Carousel>

//         </div>
//     )
// }

// export default Categories;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import "./Categories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faGraduationCap, faDollarSign, faBriefcase, faLaptop, faBullhorn, faUtensils, faTruck, faPlane, faWrench } from "@fortawesome/free-solid-svg-icons";

function Categories({ searchTerm }) {
  const [searchData, setSearchData] = useState("");

  let servicii_principale = [
    "Servicii de sănătate",
    "Servicii educaționale",
    "Servicii financiare",
    "Servicii de consultanță în afaceri",
    "Servicii IT și tehnologie",
    "Servicii de marketing și publicitate",
    "Servicii de catering și evenimente",
    "Servicii de transport și logistică",
    "Servicii de turism și ospitalitate",
    "Servicii de construcții și reparații"
  ];

  const handleCategory = (term) => {
    setSearchData(term);
    searchTerm(term);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  const categoryIcons = {
    "Servicii de sănătate": faHospital,
    "Servicii educaționale": faGraduationCap,
    "Servicii financiare": faDollarSign,
    "Servicii de consultanță în afaceri": faBriefcase,
    "Servicii IT și tehnologie": faLaptop,
    "Servicii de marketing și publicitate": faBullhorn,
    "Servicii de catering și evenimente": faUtensils,
    "Servicii de transport și logistică": faTruck,
    "Servicii de turism și ospitalitate": faPlane,
    "Servicii de construcții și reparații": faWrench,
  };

  return (
    <div className="carouseld" id="carouseldiv">
    <Carousel responsive={responsive} id="carousel">
      {servicii_principale.map((category) => (
        <div className="catbtn" key={category} onClick={() => handleCategory(category)}>
          <FontAwesomeIcon icon={categoryIcons[category]} className="category-icon" />
          {category}
        </div>
      ))}
    </Carousel>
  </div>
  );
}

export default Categories;