import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AnuntModal from './AnuntModal';
import jwt_decode from 'jwt-decode';
import EditAnunt from './EditAnunt';
import { useNavigate } from 'react-router-dom';

function ListaAnunt() {
  const [anunturi, setAnunturi] = useState([]);
  const [selectedAnunt, setSelectedAnunt] = useState(null);
  const token = localStorage.getItem('token');
  const decoded = jwt_decode(token);
  let navigate = useNavigate();
  

  const getAnunturi = () => {
    let userId = decoded.userId;

    axios.get(`http://localhost:5001/listaanunturi/${userId}`)
      .then((response) => {
        setAnunturi(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAnunturi();
  }, []);

  function deleteAnunt(idanunt){
    axios.delete(`http://localhost:5001/listaanunturi/${idanunt}`)
      .then(() => {
        getAnunturi();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function editAnunt(idanunt){
    axios.put(`http://localhost:5001/listaanunturi/${idanunt}`)
      .then(() => {
        getAnunturi();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div classname="table-div" style={{overflow: "scroll"}}>
      <table class="table-lista">
        <thead>
          <tr>
            <th>Nume anunț</th>
            <th>Data expirării</th>
            <th>Locație</th>
            <th>Categorie</th>
            <th>Preț</th>
            <th>Descriere</th>
          
            
          </tr>
        </thead>
        <tbody>
          {anunturi.map((anunt) => (
            <tr key={anunt.idanunt}>
              <td>{anunt.nume_anunt}</td>
              
              <td>{new Date(anunt.data_exp).toLocaleDateString()}</td>
              <td>{anunt.location}</td>
              <td>{anunt.category}</td>
              <td>{anunt.price}</td>
              <td>{anunt.description}</td>
              
              <td>
                <button onClick={() => deleteAnunt(anunt.idanunt)}>Sterge</button>
                <button onClick={() => navigate(`/editanunt/${anunt.idanunt}`, {state: {anunt}})}>Editeaza</button>
               
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      
    </div>
  );
}

export default ListaAnunt;