import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../resources/logo.png';
import { Link } from 'react-router-dom';





function NavBar () {

    const [i, setI] = useState('');
    const [iUserProfie, setIUserProfile] = useState('');
    const navigate = useNavigate();
    
    const token = localStorage.getItem('token');


    
    
  
    useEffect(() => {
      
      if(token){
        setI("Deconecteaza-te");
        setIUserProfile("Contul meu");}
      else{
        setI("Conecteaza-te") ;
        setIUserProfile("");}
        
  
  
      
      
    }, [token]);


    return (
        <div>
             <nav id="navig" >
            
            <Link to="/"  id="logo">
              <h2>e-tenet.ro</h2>
            </Link>
          
             <div className='rightc'id="rightbuttons">
              <Link to="/userprofile" style={{color: "black"}}>{iUserProfie}</Link>
              <button className="nav-btn" onClick={() => {if (token) {
                 navigate('/adaugareanunt');
                } else {
                    alert("Trebuie sa fii autentificat sa postezi");
                    navigate('/signinup');
                }}}>Adaugare Anunt</button>
                
              <button className="nav-btn" onClick={() => {
                if(token){
                  localStorage.removeItem('token');
                  setI("Conecteaza-te");
                  
                  } else {
                    navigate('/signinup');
                    
                  }
                    }
                    }>{i}</button>
              
              </div> 


            
            
          
        </nav>

        </div>
    )



}

export default NavBar;