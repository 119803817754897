import React, { useState } from 'react';
import './contact.css'

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const emailSubject = `Subject: ${subject}`
        const emailBody = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
    
        const mailtoLink = `mailto:tenet1488@gmail.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    
        window.location.href = mailtoLink;
    };

    return (
        <div>
            <div className="contact1">

            
            <h1>Contactează-ne</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Nume:</label>
                <input
                    type="text"
                    id="namec"
                    placeholder='Introdu-ti numele'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="emailc"
                    placeholder='Introdu-ti adresa de email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="Subiect">Subiect:</label>
                <input
                    type="text"
                    id="subjectc"
                    
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />



                <label htmlFor="message">Message:</label>
                <input
                    id="messagec"
                    value={message}
                    placeholder='Trimite-ne un mesaj'
                    onChange={(e) => setMessage(e.target.value)}
                ></input>

                <button type="submit">Trimite</button>
            </form>
            </div>
        </div>
    );
}

export default Contact;

