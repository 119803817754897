import React, { useEffect } from "react"; import './signinup.css';
import axios from 'axios';
import { useState } from 'react';
import './SignUp.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function SignInUp() { 
    
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const [same, setSame] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      alert('You are already logged in');
      navigate('/');
      
    }
  }, [navigate]);

  const handleFormSubmitIn = (e) => {
    e.preventDefault();

    let formData = new FormData();
    
    formData.append('username', username);
    formData.append('password', password);
;

axios.post('http://localhost:5001/signin', formData,{
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
        alert(response.data.message);
        localStorage.setItem('token', response.data.token);
        navigate('/');
    })
    .catch((error) => {
        console.error(error);
        alert('Wrong username/password');
    });
;

}

  const handleFormSubmitUp = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "username": username,
        "password": password,
        
           });
  
      let formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('username', username);
      formData.append('password', password);
      
  
      console.log(data)
      // Send the form data to the server
  
    
    axios
      .post('http://localhost:5001/signup', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        alert(response.data.message);
        // TODO: Handle successful sign-up
        localStorage.setItem('token', response.data.token);
        navigate('/userprofile');
      })
      .catch((error) => {
        console.error(error);
        // TODO: Handle sign-up error
      });
  }
    
  
    useEffect(() => { 
        
        const signUpButton = document.getElementById('signUp'); 
        const signInButton = document.getElementById('signIn'); 
        const container = document.getElementById('container');

    signUpButton.addEventListener('click', () => {
        container.classList.add("right-panel-active");
    });

    signInButton.addEventListener('click', () => {
        container.classList.remove("right-panel-active");
    });
}, []);

return (
    <div className="tot">
        <div className="container" id="container">
            
            <div className="form-container sign-in-container">
                <form onSubmit={handleFormSubmitIn}>
                    <h1>Intră în cont</h1>
                    {/* <div className="social-container">
                        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                    </div> */}
                    <span></span>
                    <input type="text" id="username" value={username} placeholder="Nume Utilizator" onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" id="password" value={password} placeholder="Parola" onChange={(e) => setPassword(e.target.value)} />
                    <a href="#">Ți-ai uitat parola?</a>
                    <button type="submit">Continuă</button>
                </form>
            </div>
            <div className="form-container sign-up-container">
                <form onSubmit={handleFormSubmitUp}>
                    <h1>Creează-ți cont</h1>
                    {/* <div className="social-container">
                        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                    </div> */}
                    
                    <input id="firstName" type="text" value={firstName} placeholder="Prenume" onChange={(e) => setFirstName(e.target.value)} />
                    <input id="lastName" type="text" value={lastName} placeholder="Nume" onChange={(e) => setLastName(e.target.value)} />
                    <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}  placeholder="Nume Utilizator (ex: ionut2023)" />
                    <input id="email" type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                    <input id="password" type="password" value={password} placeholder="Parola" onChange={(e) => {
            setPassword(e.target.value);
            if(e.target.value!==confirmPassword){setSame('Parola nu coincide!')}
            else setSame('')}} />
                    <span>{same}</span>
                    <input id="confirmPassword" type="password" value={confirmPassword} placeholder="Reintrodu parola" onChange={(e) => {
            setConfirmPassword(e.target.value);
            if(e.target.value!==password){setSame('Parola nu coincide!')}
            else setSame('')}} /> 
                    
                    <button type="submit">Inregistreaza-te</button>
                </form>
            </div>
            <div className="overlay-container">
                <div className="overlay">
                    <div className="overlay-panel overlay-left">
                        <h1>Bine ai revenit!</h1>
                        <p>Introdu-ți datele pentru a-ți accesa contul</p>
                        <button className="ghost" id="signIn">Intră în cont</button>
                    </div>
                    <div className="overlay-panel overlay-right">
                        <h1>Bine ai venit!</h1>
                        <p>Introdu-ți datele și fă-ți un cont nou</p>
                        <button className="ghost" id="signUp">Creează-ți cont</button>
                    </div>
                </div>
            </div>
    
        </div>
    
        
    </div>
);
}

export default SignInUp;