
import React, { useState } from 'react';
import Footer from './Footer';

function ContactM() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const emailSubject = `Subject: ${subject}`
        const emailBody = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
    
        const mailtoLink = `mailto:tenet1488@gmail.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    
        window.location.href = mailtoLink;
    };

    return (
        <div>
        <div className='contact-main'>
            
            <div className="contact1">

            
            <h1>Contactează-ne</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Nume:</label>
                <input
                    type="text"
                    id="namec"
                    placeholder='Introdu-ti numele'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="emailc"
                    placeholder='Introdu-ti adresa de email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="Subiect">Subiect:</label>
                <input
                    type="text"
                    id="subjectc"
                    
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />



                <label htmlFor="message">Message:</label>
                <input
                    id="messagec"
                    value={message}
                    placeholder='Trimite-ne un mesaj'
                    onChange={(e) => setMessage(e.target.value)}
                ></input>

                <button type="submit">Trimite</button>
            </form>
            </div>
            <div className="map">
            <div className="map-container">
  <iframe
    title="Google Maps"
    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2733.113477903877!2d23.557573076597507!3d46.76265964587335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDbCsDQ1JzQ1LjYiTiAyM8KwMzMnMzYuNSJF!5e0!3m2!1sro!2sro!4v1705687472734!5m2!1sro!2sro"
    width="100%"
    height="0"
    style={{ border: '0' }}
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</div>
                </div>
                <div className='datecontact'>
                <div className='adresa'>
                <h1>Adresa:</h1>
                <h5>Strada Plopilor, nr. 73, Cluj-Napoca, Romania</h5>
                </div>
                <div className='telefon'>
                <h1>Telefon:</h1>
                <h5>0724 869 426</h5>
                </div>
                <div className='email'>
                <h1>Email:</h1>
                <h5>tenet1488@gmail.com</h5>
                </div>
            
            </div>
            

        
        </div>
        <div className="footer">
              <Footer />
              </div>
        </div>
        
    );
}

export default ContactM;

