import './App.css';
import React from 'react';
import { useState , useEffect} from 'react';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import Anunt from './pages/Anunt';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import UserProfile from './pages/UserProfile';
import SignIn from './pages/SignIn';
import AdaugareAnunt from './pages/AdaugareAnunt';
import SearchPage from './pages/SearchPage';
import SearchResults from './pages/SearchResults.js';
import SearchBar from './pages/SearchBar.js';
import SignInUp from './pages/SignInUp';
//import Home2 from './pages/Home2v';
import Logo from './resources/logo.png';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import TermeniConditii from './pages/TermeniConditii';
import placuta from './resources/AIMMAIPE-Cluj-scaled.jpg';
import NavBar from './pages/NavBar';
import "./global.css";
import "./pages/Footer.js";
import CSignInUp from './pages/CSignInUp.js';
import ContactM from './pages/ContactM.js';
import EditAnunt from './pages/EditAnunt.js';


function App() {
  

 


  return (
    
      <div>
        
        <NavBar />
        
        <Routes>
          <Route exact path="/" element={<Home />} />
          
          <Route exact path="/" element={<SearchBar/>} />
          <Route path="/searchresults" element={<SearchResults/>} />
          <Route path="/signinup" element={<SignInUp />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/anunt/:id" element={<Anunt/>} />
          <Route path="/editanunt/:id" element={<EditAnunt/>} />
          <Route path="/adaugareanunt" element={<AdaugareAnunt />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/termeniconditii" element={<TermeniConditii />} />
          <Route path="/contact" element={<ContactM />} />
        </Routes>
        
      </div>
    
  );
}

export default App;
